import React from 'react';

import PhoneIcon from '@svg/phone.svg';

import { ModuleWrapper } from '../moduleWrapper';
import { SanityImage } from '@components/sanity/sanityImage';

const BioCard = ({ name, phone, position, image }) => {
  return (
    <div className="text-center">
      <div className="aspect-w-1 aspect-h-1 relative rounded-xl overflow-hidden">
        <SanityImage image={image} className="absolute inset-0 object-cover" />
      </div>
      <div className="mt-4">
        <h4 className="font-medium text-2xl">{name}</h4>
        <h5 className="font-medium text-base my-half">{position}</h5>
        <a href={`tel:${phone}`} className="btn large">
          <PhoneIcon className="w-3 mr-2 self-center fill-current" />
          {phone}
        </a>
      </div>
    </div>
  );
};

const BioGrid = ({ config, items }) => {
  return (
    <ModuleWrapper {...config}>
      <div className="px-gutter grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3">
        {items.map((item) => (
          <BioCard {...item} />
        ))}
      </div>
    </ModuleWrapper>
  );
};

export default BioGrid;
